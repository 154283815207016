import { Injectable } from '@angular/core';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Disposable } from '../../shared/extensions/disposable';
import { StateSubject } from '../../shared/extensions/state-subject';
import { SideNavItem } from '../components/side-nav/models/side-nav-item';
import {authorizeMap} from '../../auth/common/authorize-map';
import {AuthService} from "../../auth/auth.service";

export type SideNavState = MatDrawerToggleResult | 'mini';

@Injectable({
  providedIn: 'root',
})
export class SideNavService extends Disposable {

  private $sideNav = new StateSubject<MatSidenav>(null);
  private $sideNavState = new StateSubject<SideNavState>('open');
  private sideNavItem = new StateSubject<SideNavItem[]>([]);
  matSideNavAnimationLength = 250;

  constructor(private authService: AuthService) {
    super();
    this.sideNavState$
      .pipe(
        skipWhile(() => !this.sideNav),
        takeUntil(this.$destroyed),
      )
      .subscribe(state => {
        if (state === 'open') {
          this.sideNav?.open().then();
        } else if (state === 'close') {
          this.sideNav?.close().then();
        }
      });
  }

  get sideNavState$(): Observable<SideNavState> {
    return this.$sideNavState.asObservable();
  }

  get sideNav(): MatSidenav {
    return this.$sideNav.getValue();
  }

  setSidenav(sideNav: MatSidenav): void {
    this.$sideNav.setState(sideNav);
  }

  setSideNavItems(sideNavItems: SideNavItem[]): void {
    this.sideNavItem.setState(
        this._filterSideNavItems(sideNavItems),
    );
  }

  getSideNavItems(): Observable<SideNavItem[]> {
    return this.sideNavItem.asObservable();
  }

  toggle(): void {
    this.$sideNavState.setState(prev => {
      return prev === 'open' ? 'close' : 'open';
    });
    setTimeout(() => window.dispatchEvent(new Event('resize')), this.matSideNavAnimationLength);

  }

  toggleOnScreenChanged(isHandset: boolean): void {
    this.$sideNavState.setState(prev => {
      if (isHandset) {
        return 'close';
      }
      return prev === 'open' ? prev : 'mini';
    });
  }

  private _filterSideNavItems(sideNavItems: SideNavItem[]): SideNavItem[] {

    const navsAuthorized: SideNavItem[] = [];

    sideNavItems = sideNavItems.filter(sn => {
      if (!sn.visible) {
        return false;
      }

      if (sn.authorize) {
        return this._isUserAuthorized(sn);
      }
      return true;
    });

    sideNavItems.forEach(sideNav => {

        if (sideNav.children) {
          sideNav.children = sideNav.children.filter(child => {
            return this._isUserAuthorized(child);
          });
          navsAuthorized.push(sideNav);
        } else {
          navsAuthorized.push(sideNav);
        }
    });

    return navsAuthorized;

  }

  private _isUserAuthorized(sideNav: SideNavItem): boolean {
    if (!sideNav.authorize) {
      return true;
    }
    const test = authorizeMap[sideNav.authorize];
    const sideNavPermissions = authorizeMap[sideNav.authorize].permissions;
    return this.authService.hasAnyPermission(sideNavPermissions);
  }

}
