import { Component } from '@angular/core';
import { FiscalYearService } from './shared/services/fiscal-year.service';
import { AuthService } from './auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private readonly fiscalYearService: FiscalYearService,
    private readonly authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    // authenticate
    this.authService.runInitialLoginSequence().then();
  }
}
