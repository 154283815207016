import {Component, OnInit} from '@angular/core';
import {of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil, mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Disposable} from '../../../shared/extensions/disposable';
import {ProgressService} from '../../services/progress.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent extends Disposable implements OnInit {
    get searchText(): string {
        return this._searchText;
    }

    set searchText(searchText: string) {
        this._searchText = searchText && typeof searchText === 'string' ? searchText.trim() : '';
    }

    private _searchText = '';
    searchTextSubject = new Subject<string>();
    searchLimit = 5;
    results: any[];
    searching = false;

    constructor(private router: Router, private progress: ProgressService) {
        super();
    }

    ngOnInit(): void {
        // this.searchTextSubject
        //     .pipe(debounceTime(1000), takeUntil(this.$destroyed), mergeMap((_) => {
        //         return this.searchText ? this.someClient.quickSearch(this.searchText, this.searchLimit) : of({cases: []});
        //     })).subscribe({
        //     next: (next) => {
        //         this.results = next.results;
        //         this.searching = false;
        //     }
        // });
    }

    onSearchTextInput(): void {
        if (!this.searchText) {
            return;
        }
        this.searching = true;
        this.searchTextSubject.next(this.searchText);
    }

    onResultClick(result: any): void {
        // if (!result) {
        //     return;
        // }
        // this.progress.setLoadingState(true);
        // this.searchText = '';
        // this.router.navigate([path, result.id]).then(() => {
        //     this.progress.setLoadingState(false);
        // });
    }
}
