import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotAuthorizedComponent} from './components/should-login/should-login.component';
import { AuthCallbackComponent } from './components/auth-call-bck/auth-callback.component';

export const authRoutes: Routes = [
  {
    path: 'signin-oidc',
    component: AuthCallbackComponent,
    data: {
      title: 'Authorizing'
    }
  },
  {path: 'not-authorized', component: NotAuthorizedComponent},
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
