
<div #spacer></div>
<div class="on-top" stickyThing [spacer]="spacer">
    <mat-toolbar class=" mat-elevation-z2 header-toolbar">
            <mat-toolbar-row class="d-flex justify-content-center">
                <span class="header-span-alignment-left">
                    <button *ngIf="!sideNavVisible" mat-icon-button (click)="expandClick()">
                        <mat-icon>menu_open</mat-icon>
                    </button>
                </span>
                <span>
                    <img *ngIf="!(isHandSetOrTablet$ | async)" src="assets/images/white-washu-logo.png" routerLink="/" class="header-image align-self-center" alt="WUSTL" />
                    <img *ngIf="(isTablet$ | async)" src="assets/images/white-washu-logo.png" routerLink="/" class="ipad-header-image align-self-center" alt="WUSTL" />
                </span>
                <div class="header-span-alignment-right">
                    <app-user-info
                        [userName]="(currentUser$ | async)?.email"
                        [lighter]="false"
                        [ngClass]="(isHandSet$ | async)
                      ? 'ml-auto'
                      : 'ml-4' "
                        class="align-self-center">
                    </app-user-info>
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="ml-2">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
    <mat-progress-bar *ngIf="isLoading$ | async" mode="indeterminate" color="accent"></mat-progress-bar>
</div>
<mat-menu #menu="matMenu">
    <div mat-menu-item (click)="onProfileClick()">
        <mat-icon>account_circle</mat-icon>
        <app-user-info [userName]="(currentUser$ | async)?.fullName"></app-user-info>
    </div>
    <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
    </button>
</mat-menu>
