import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthenticationWithLoginGuard} from "./auth/guards/authentication-with-login.guard";

const routes: Routes = [
  { path: '', redirectTo: '/pep-system/applications/manage', pathMatch: 'full' },
  {
    path: 'pep-system',
    canActivate: [AuthenticationWithLoginGuard],
    data: { isInternal: true },
    loadChildren: () => import('./layout/pep-layout/pep-layout.module').then((module) => module.PepLayoutModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(
        module => module.AuthModule
    ),
  },
  { path: '**', redirectTo: '/pep-system/applications/manage' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
