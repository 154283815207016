import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SearchComponent} from './components/search/search.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {UserInfoComponent} from './components/user-info/user-info.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AuthModule} from '../auth/auth.module';

@NgModule({
    declarations: [HeaderComponent, SideNavComponent, SearchComponent, UserInfoComponent],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatSidenavModule,
        MatInputModule,
        MatFormFieldModule,
        MatMenuModule,
        MatExpansionModule,
        MatListModule,
        RouterModule,
        AngularStickyThingsModule,
        MatProgressBarModule,
        FormsModule,
        ReactiveFormsModule,
        AuthModule
    ],
    exports: [
        HeaderComponent,
        SideNavComponent,
        SearchComponent,
        UserInfoComponent,
    ]
})
export class LayoutModule {
}
