import { StorageType } from '../enums/storage-type.enum';
import { InjectionToken } from '@angular/core';

export const KeyPrefix = new InjectionToken<string>('KeyPrefix');

export abstract class StorageBase {
  private readonly _keyPrefix: string;
  protected readonly initialized: boolean = false;
  protected readonly storageType: StorageType;

  public readonly storageEnabled: boolean = false;
  public readonly storage: Storage;

  protected constructor(storageType: StorageType, keyPrefix: string = '') {
    this.storageType = storageType;
    this._keyPrefix = keyPrefix?.length ? keyPrefix + '-' : '';
    const storageAvailable = storageType === StorageType.Session ? !!window.sessionStorage : !!window.localStorage;
    if (!storageAvailable) {
      this.storageEnabled = false;
      console.error(`Current browser does not support ${this.storageType} Storage`);
      return;
    }
    this.storageEnabled = true;
    this.storage = this.storageType === StorageType.Session ? window.sessionStorage : window.localStorage;
    this.initialized = true;
  }

  public exists(key: string): boolean {
    if (!this.storageEnabled) {
      return false;
    }
    return this.storage.hasOwnProperty(this.buildKey(key));
  }

  public set(key: string, value: string): void {
    if (!this.storageEnabled) {
      return;
    }
    if (!this.initialized) {
      throw Error(`Current browser does not support ${this.storageType} Storage`);
    }

    this.storage[this.buildKey(key)] = value;
  }

  public get(key: string): string {
    if (!this.storageEnabled) {
      return '';
    }
    if (!this.initialized) {
      throw Error(`Current browser does not support ${this.storageType} Storage`);
    }
    return this.storage[this.buildKey(key)] || false;
  }

  public setObject(key: string, value: any): void {
    if (!this.storageEnabled)
      return;
    if (!this.initialized) {
      throw Error(`Current browser does not support ${this.storageType} Storage`);
    }
    if (!value)
      return;

    this.storage[this.buildKey(key)] = JSON.stringify(value);
  }

  public getObject(key: string): any {
    if (!this.storageEnabled) {
      return null;
    }
    if (!this.initialized) {
      throw Error(`Current browser does not support ${this.storageType} Storage`);
    }
    return JSON.parse(this.storage[this.buildKey(key)] || '{}');
  }

  public getValue<TType>(key: string): TType {
    if (!this.storageEnabled) {
      return null;
    }
    if (!this.initialized) {
      throw Error(`Current browser does not support ${this.storageType} Storage`);
    }
    const obj = JSON.parse(this.storage[this.buildKey(key)] || null);
    return <TType>obj || null;
  }

  public remove(key: string): any {
    if (!this.storageEnabled) {
      return null;
    }
    if (!this.initialized) {
      throw Error(`Current browser does not support ${this.storageType} Storage`);
    }
    this.storage.removeItem(this.buildKey(key));
  }

  public clear() {
    if (!this.storageEnabled) {
      return;
    }
    if (!this.initialized) {
      throw Error(`Current browser does not support ${this.storageType} Storage`);
    }
    this.storage.clear();
  }

  private buildKey(key: string): string {
    return `${this._keyPrefix}${key}`;
  }
}
