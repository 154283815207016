<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" class="nav-container" fixedInViewport="true">
    <div class="d-flex flex-column h-100">
      <div class="d-flex flex-row mt-2">
        <img src="assets/images/pep-logo.png" routerLink="/" class="header-image ml-auto" alt="WUSTL" />
        <button mat-icon-button class="ml-auto" (click)="toggleSideNav()">
          <mat-icon>first_page</mat-icon>
        </button>
      </div>
      <button mat-icon-button class="pl-4 mb-2 dashboard-button" routerLink="/pep-system/dashboard">
        <mat-icon class="mr-2">dashboard</mat-icon>
        DASHBOARD
      </button>
      <button mat-icon-button class="pl-4 mb-2 dashboard-button" routerLink="/pep-system/advanced-search">
        <mat-icon class="mr-2">search</mat-icon>
        ADVANCED SEARCH
      </button>
      <mat-accordion class="mt-2 nav-menu" multi>

        <mat-expansion-panel
                [expanded]="i === 0"
                *ngFor="let item of sideNavItems$ | async; let i = index"
                class="mat-elevation-z0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="mr-2">{{ item.icon }}</mat-icon>
              {{ item.title | uppercase }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row w-100 no-gutters pl-3" *ngFor="let navItem of item.children">
            <button mat-button class="w-100 text-left nav-menu-item" [routerLink]="navItem.route">
              {{ navItem.title }}
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-header
      (expandSideMenuClick)="toggleSideNav()"
      [sideNavVisible]="(sideNavState$ | async) === 'open'"
    ></app-header>
    <div class="set-height scroll-vert">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
