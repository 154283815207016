import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthenticationWithLoginGuard } from './auth/guards/authentication-with-login.guard';
import { KeyPrefix } from './shared/abstracts/storage-base';
import { ENV_CONFIG, EnvConfig } from '../configuration';
import { XsrfInterceptor } from './shared/interceptors/xsrf.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true
      }
    })
  ],
  providers: [
    AuthenticationWithLoginGuard,
    {
      provide: KeyPrefix,
      useFactory: (envConfig: EnvConfig) => envConfig.appConfig.storageKeyPrefix,
      deps: [ENV_CONFIG]
    },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
