import { Injectable } from '@angular/core';
import {
  AddFiscalYearRequest,
  FiscalYearClient,
  FiscalYearViewModel,
  GetFiscalYearsResponse,
  PublicClient,
  UpdateFiscalYearsRequest
} from '../apis/api.client';
import { BehaviorSubject, Observable } from 'rxjs';
import { Options } from '../types';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class FiscalYearService {
  _fiscalYearsSubject = new BehaviorSubject<FiscalYearViewModel[]>([]);
  fiscalYears$ = this._fiscalYearsSubject.asObservable();
  private _systemFiscalYearSubject = new BehaviorSubject<FiscalYearViewModel>(null);
  private _fpcwFiscalYearSubject = new BehaviorSubject<FiscalYearViewModel>(null);
  private _pepApplicationFiscalYearSubject = new BehaviorSubject<FiscalYearViewModel>(null);
  private _loadingSubject = new BehaviorSubject<boolean>(false);

  get isLoading$(): Observable<boolean> {
    return this._loadingSubject.asObservable();
  }

  get fiscalYears(): ReadonlyArray<FiscalYearViewModel> {
    return this._fiscalYearsSubject.value;
  }

  get systemFiscalYear(): FiscalYearViewModel {
    return this._systemFiscalYearSubject.value;
  }

  get fpcwFiscalYear(): FiscalYearViewModel {
    return this._fpcwFiscalYearSubject.value;
  }

  get pepApplicationFiscalYear(): FiscalYearViewModel {
    return this._pepApplicationFiscalYearSubject.value;
  }

  constructor(private fiscalYearClient: FiscalYearClient, private publicClient: PublicClient) {}

  getFiscalYearsCopy(): FiscalYearViewModel[] {
    return this.fiscalYears.slice();
  }

  loadPublicFiscalYears(callback: () => void = () => {}): void {
    this.fetchFiscalYears(callback, this.publicClient.getFiscalYears());
  }

  loadFiscalYears(callback: () => void = () => {}): void {
    this.fetchFiscalYears(callback, this.fiscalYearClient.get());
  }

  private fetchFiscalYears(callback: () => void = () => {}, apiCall: Observable<GetFiscalYearsResponse>): void {
    this._loadingSubject.next(true);
    apiCall.subscribe(
      (next) => {
        const fiscalYearsSortedByCreatedDate = next.fiscalYears.sort(
          (a, b) => a.createdDate.getTime() - b.createdDate.getTime()
        );
        this._fiscalYearsSubject.next(fiscalYearsSortedByCreatedDate);
        this._systemFiscalYearSubject.next(this.getSystemFiscalYear());
        this._fpcwFiscalYearSubject.next(this.getFpcwFiscalYear());
        this._pepApplicationFiscalYearSubject.next(this.getPepApplicationFiscalYear());
        this._loadingSubject.next(false);
        callback();
      },
      (error) => {
        this._loadingSubject.next(false);
        swal.fire({
          heightAuto: false,
          title: 'Error',
          text: `An unexpected error occurred while trying to retrieve fiscal year information.`,
          icon: 'error'
        });
      }
    );
  }

  private getSystemFiscalYear(): FiscalYearViewModel {
    return this.fiscalYears.find((fy) => fy.isSystemFiscalYear);
  }

  private getFpcwFiscalYear(): FiscalYearViewModel {
    return this.fiscalYears.find((fy) => fy.isFpcwFiscalYear);
  }

  private getPepApplicationFiscalYear(): FiscalYearViewModel {
    return this.fiscalYears.find((fy) => fy.isPepApplicationFiscalYear);
  }

  getFiscalYearOptions(): Options<FiscalYearViewModel> {
    const options = this.getFiscalYearsCopy().map((fy) => {
      return {
        label: `${fy.year - 1} - ${fy.year}`,
        value: fy
      };
    });
    return options.sort((a, b) => a.value.year - b.value.year);
  }

  getFiscalYearById(id): FiscalYearViewModel {
    return this.fiscalYears.find((fy) => fy.id === id);
  }

  getFiscalYearLabel(fiscalYear: FiscalYearViewModel): string {
    return `${fiscalYear.year - 1} - ${fiscalYear.year}`;
  }

  updateFiscalYears(request: UpdateFiscalYearsRequest, callback: () => void = () => {}): void {
    this._loadingSubject.next(true);
    this.fiscalYearClient.update(request).subscribe(
      () => {
        this._loadingSubject.next(false);
        this.loadFiscalYears(callback);
      },
      (error) => {
        this._loadingSubject.next(false);
        swal.fire({
          heightAuto: false,
          title: 'Error',
          text: `An unexpected error occurred while trying to update fiscal years.`,
          icon: 'error'
        });
      }
    );
  }

  addFiscalYear(request: AddFiscalYearRequest, callback: () => void = () => {}): void {
    this._loadingSubject.next(true);
    this.fiscalYearClient.add(request).subscribe(
      () => {
        this._loadingSubject.next(false);
        this.loadFiscalYears(callback);
      },
      (error) => {
        this._loadingSubject.next(false);
        swal.fire({
          heightAuto: false,
          title: 'Error',
          text: `An unexpected error occurred while trying to add a fiscal year.`,
          icon: 'error'
        });
      }
    );
  }
}
