<div class="quick-search-header">
<mat-form-field appearance="outline" class="ml-2">
    <input type="text" [(ngModel)]="searchText" (ngModelChange)="onSearchTextInput()" matInput
           [matAutocomplete]="auto"
           placeholder="Search...">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onResultClick($event.option.value)">
        <ng-container *ngIf="searchText">
            <ng-container *ngIf="searching; else results">
                <mat-option>Searching...</mat-option>
            </ng-container>
            <ng-template #results>
                <ng-container *ngIf="results?.length !== 0; else noResults">
                    <ng-container *ngFor="let result of results">
                        <mat-option [value]="result">
                            {{result.firstName + ' ' + result.lastName}}
                            <span class="search-result-subtext">
                            {{result.id}}
                            </span>
                        </mat-option>
                    </ng-container>
                </ng-container>
                <ng-template #noResults>
                    <mat-option>
                        No results found.
                    </mat-option>
                </ng-template>
            </ng-template>
        </ng-container>
    </mat-autocomplete>
    <button type="button" mat-icon-button matSuffix>
        <mat-icon>search</mat-icon>
    </button>
</mat-form-field>
</div>
