import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {ENV_CONFIG, EnvConfig} from './configuration';
import {ApiBaseUrl} from './app/shared/apis/api.client';

(async () => {
  try {
    let res =  await fetch('assets/data/configuration.json');
    let envConfig = await res.json() as EnvConfig;

    if (envConfig.production) {
      enableProdMode();
    }

    if (envConfig.localTesting) {
      res =  await fetch('assets/data/test-configuration.json');
      envConfig = await res.json() as EnvConfig;
    }

    platformBrowserDynamic([
      { provide: ENV_CONFIG, useValue: envConfig },
      { provide: ApiBaseUrl, useValue: envConfig.appConfig.apiBaseUrl },
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  } catch (e) {
    console.error(`Load pre-initialize configuration error: ${e}`);
  }
})();
