import { Injectable } from '@angular/core';
import { Disposable } from '../../shared/extensions/disposable';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService extends Disposable {
  private isLoading = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  setLoadingState(loading: boolean): void {
    this.isLoading.next(loading);
  }

  get loading(): boolean {
    return this.isLoading.getValue();
  }
}
