import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import {AuthService} from "../auth.service";


@Directive({
  selector: '[appAuthorize]',
})
export class AuthorizedDirective {
  constructor(
    private readonly viewRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private auth: AuthService
  ) {}

  @Input()
  set appAuthorize(permission: string) {
    if (!permission) {
      this.viewRef.createEmbeddedView(this.templateRef);
      return;
    }

    const hasPermissions = this.auth.hasPermission(permission);
    if (hasPermissions) {
      this.viewRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef.clear();
    }
  }
}

@Directive({
  selector: '[appAuthorizeAny]',
})
export class AuthorizedAnyDirective {
  constructor(
      private readonly viewRef: ViewContainerRef,
      private readonly templateRef: TemplateRef<any>,
      private auth: AuthService
  ) {}

  @Input()
  set appAuthorizeAny(permissions: string[]) {
    if (!permissions || permissions.length === 0) {
      this.viewRef.createEmbeddedView(this.templateRef);
      return;
    }

    const hasPermissions = this.auth.hasAnyPermission(permissions);
    if (hasPermissions) {
      this.viewRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewRef.clear();
    }
  }
}
