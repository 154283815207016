export enum Permissions {
    ssnRead = 'ssn::read',
    fyRead = 'fiscalyear::read',
    fyUpdate = 'fiscalyear::update',
    fyCreate = 'fiscalyear::create',
    fyDelete = 'fiscalyear::delete',
    costRead = 'cost::read',
    costUpdate = 'cost::update',
    costCreate = 'cost::create',
    termsRead = 'programterms::read',
    termsUpdate = 'programterms::update',
    termsCreate = 'programterms::create',
    utilRead = 'utilities::read',
    utilUpdate = 'utilities::update',
    utilCreate = 'utilities::create',
    applicationRead = 'application::read',
    applicationDelete = 'application::delete',
    applicationRefinance = 'application::refinance',
    applicationDetailsRead = 'applicationdetails::read',
    applicationDetailsUpdate = 'applicationdetails::update',
    search = 'search',
    summaryRead = 'summary::read',
    summaryCreate = 'summary::create',
    summaryUpdate = 'summary::update',
    planCreate = 'plan::create',
    approvalLvl1 = 'summary::approve::lvl1',
    approvalLvl2 = 'summary::approve::lvl2',
    approvalLvl3 = 'summary::approve::lvl3',
    approvalLvl4 = 'summary::approve::lvl4',
    accountRead = 'account::read',
    accountCancel = 'account::cancel',
    accountDelete = 'account::delete',
    semestersRead = 'semesters::read',
    semestersUpdate = 'semesters::update',
    reductionRead = 'reduction::read',
    reductionCreate = 'reduction::create',
    reductionUpdate = 'reduction::update',
    reductionDelete = 'reduction::delete',
    prepaymentRead = 'prepayment::read',
    prepaymentCreate = 'prepayment::create',
    prepaymentUpdate = 'prepayment::update',
    tranRead = 'transactions::read',
    tranCreate = 'transactions::create',
    transactionCodeCreate = 'transactioncode::create',
    transactionCodeUpdate = 'transactioncode::update',
    transactionCodeRead = 'transactioncode::read',
    uasOutboundCreate = 'uasoutbound::create',
    correspondenceCreate = 'correspondence::create',
    correspondenceRead = 'correspondence::read',
    correspondenceUpdate = 'correspondence::update',
    correspondenceDelete = 'correspondence::delete',
    tableMaintenanceRead = 'tablemaintenance::read',
    tableMaintenanceUpdate = 'tablemaintenance::update',
    riskRead = 'risk::read',
    riskUpdate = 'risk::update',
    userRead = 'user::read',
    userUpdate = 'user::update',
    userCreate = 'user::create',
    roleRead = 'role::read',
    roleCreate = 'role::create',
    roleUpdate = 'role::update',
    employeeRead = 'employee::read',
    accountUpdate = 'account::update',
    prepaymentDelete = 'prepayment::delete',
    roleDelete = 'role::delete',
    saTransactionsCreate = 'satransactions::create',
    configRead = 'configurations::read',
    ssnUpdate = 'ssn::update',
    baseYearAccountJournalRead = 'baseYearAccountJournal::read',
    baseYearAccountJournalCreate = 'baseYearAccountJournal::create'
}
