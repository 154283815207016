import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Observable} from 'rxjs';
import {SideNavService, SideNavState} from '../../services/side-nav.service';
import {SideNavItem} from './models/side-nav-item';
import {BreakpointService} from '../../../shared/services/breakpoint.service';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Disposable} from '../../../shared/extensions/disposable';
import {sideNavItems} from './side-nav-items';
import {AuthService} from "../../../auth/auth.service";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent extends Disposable implements OnInit, AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  sideNavItems$: Observable<SideNavItem[]>;

  constructor(private readonly sideNavService: SideNavService,
              private readonly breakpointService: BreakpointService,
              private authService: AuthService) {
    super();
  }

  get sideNavState$(): Observable<SideNavState> {
    return this.sideNavService.sideNavState$;
  }

  ngOnInit(): void {
      this.sideNavService.setSideNavItems(sideNavItems);
      this.sideNavItems$ = this.sideNavService.getSideNavItems();
      this.authService.user.pipe(
          filter(user => user?.permissions && user.permissions.length > 0),
          take(1),
    ).subscribe(profile => {
      this.sideNavService.setSideNavItems(sideNavItems);
      this.sideNavItems$ = this.sideNavService.getSideNavItems();
    });
  }

  ngAfterViewInit(): void {
    this.sideNavService.setSidenav(this.sidenav);
    setTimeout(() => {
      this.breakpointService.isHandset$
          .pipe(takeUntil(this.$destroyed))
          .subscribe(isHandset => {
            this.sideNavService.toggleOnScreenChanged(isHandset);
          });
    });
  }

  toggleSideNav(): void {
    this.sideNavService.toggle();
  }

}
