import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Disposable } from '../../../shared/extensions/disposable';
import { AuthService } from '../../auth.service';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Component({
  selector: 'app-auth-callback',
  template: ` <div class="text-center">Authenticating...</div> `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthCallbackComponent extends Disposable implements OnInit {
  constructor(
    private router: Router,
    private readonly authService: AuthService,
    private readonly sessionStorage: SessionStorageService
  ) {
    super();
  }

  ngOnInit(): void {
    this.authService.canActivateProtectedRoutes$
      .pipe(takeUntil(this.$destroyed))
      .pipe(filter((val: boolean) => val === true))
      .pipe(
        map(() => {
          return this.sessionStorage.get('lastUrl') || '';
        })
      )
      .subscribe((url) => {
        url = !url || !url.length ? 'pep-system/dashboard' : url;
        // Remove this last url because we dont want to mess up session state with clutter.
        this.sessionStorage.remove('lastUrl');
        this.router.navigateByUrl(url).then();
      });
  }
}
