import {Roles} from './roles';
import {Permissions} from "./permissions";

export enum Authorize {
    Admins,
    Basic,
    Entry,
    PEP4,
    Charges,
    TableMaintenance,
    FiscalYears  ,
    ProgramTerms,
    RiskTable,
    ManageUsers,
    ManageRoles,
    BrowseApplications,
    StudentAccounting,
    UserManagement,
    Configuration,
    Correspondence,
    RefinanceApplications,
    TransactionCodes,
    UasOutbound,
    BaseYearAccountJournal
}

export interface AuthorizeCollection {
    role?: Roles;
    permissions?: string[];
    claims?: string[];
}

export type AuthorizeMap = {
    [key in Authorize]?: AuthorizeCollection;
};

export const authorizeMap: AuthorizeMap = {
    [Authorize.Admins]: {
        role: Roles.Admin
    },
    [Authorize.Basic]: {
        role: Roles.Basic
    },
    [Authorize.Entry]: {
        role: Roles.Entry
    },
    [Authorize.PEP4]: {
        role: Roles.PEP4
    },
    [Authorize.Charges]: {
        permissions: [Permissions.costRead]
    },
    [Authorize.TableMaintenance]: {
        permissions: [Permissions.tableMaintenanceRead]
    },
    [Authorize.BrowseApplications]: {
        permissions: [Permissions.search]
    },
    [Authorize.StudentAccounting]: {
        permissions: [Permissions.saTransactionsCreate]
    },
    [Authorize.FiscalYears]: {
        permissions: [Permissions.fyRead]
    },
    [Authorize.ProgramTerms]: {
        permissions: [Permissions.termsRead]
    },
    [Authorize.RiskTable]: {
        permissions: [Permissions.riskRead]
    },
    [Authorize.ManageUsers]: {
        permissions: [Permissions.userRead]
    },
    [Authorize.ManageRoles]: {
        permissions: [Permissions.roleRead]
    },
    [Authorize.UserManagement]: {
        permissions: [Permissions.roleRead, Permissions.userRead]
    },
    [Authorize.Configuration]: {
        permissions: [Permissions.configRead]
    },
    [Authorize.Correspondence]: {
        permissions: [Permissions.correspondenceRead]
    },
    [Authorize.RefinanceApplications]: {
        permissions: [Permissions.applicationRefinance]
    },
    [Authorize.TransactionCodes]: {
        permissions: [Permissions.transactionCodeRead]
    },
    [Authorize.UasOutbound]: {
        permissions: [Permissions.uasOutboundCreate]
    },
    [Authorize.BaseYearAccountJournal]: {
        permissions: [Permissions.baseYearAccountJournalRead]
    }
};
