import {SideNavItem} from './models/side-nav-item';
import {Authorize} from "../../../auth/common/authorize-map";

export const sideNavItems: SideNavItem[] = [
  {
    title: 'Applications',
    icon: 'article',
    visible: true,
    route: ['/'],
    authorize: Authorize.BrowseApplications,
    children: [
      {
        title: 'Browse Applications',
        visible: true,
        route: ['/pep-system/applications/manage'],
        icon: 'note_add'
      },
      {
        title: 'Refinance Application',
        visible: true,
        route: ['/pep-system/applications/refinance'],
        authorize: Authorize.RefinanceApplications,
        icon: 'note_add'
      }
    ]
  },
  {
    title: 'CONFIGURATIONS',
    icon: 'settings',
    visible: true,
    route: ['/'],
    authorize: Authorize.Configuration,
    children: [
      {
        title: 'University Charges',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/university-charges'],
      },
      {
        title: 'Web Application Text',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/table-maintenance'],
      },
      {
        title: 'Fiscal Year Detail',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/fiscal-years'],
      },
      {
        title: 'Configure Fiscal Years',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/fiscal-years/configure'],
      },
      {
        title: 'Program Terms',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/program-terms'],
      },
      {
        title: 'Risk Control',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/risk-control']
      },
      {
        title: 'Transaction Codes',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/plan-codes'],
        authorize: Authorize.TransactionCodes
      },
      {
        title: 'Utilities',
        icon: 'note_add',
        visible: true,
        route: ['/pep-system/utilities-config']
      }
    ]
  },
  {
    title: 'USER MANAGEMENT',
    icon: 'person',
    visible: true,
    route: ['/'],
    authorize: Authorize.UserManagement,
    children: [
      {
        title: 'Manage Users',
        visible: true,
        route: ['/pep-system/app-users'],
        icon: 'note_add',
        authorize: Authorize.ManageUsers
      },
      {
        title: 'Manage Roles',
        visible: true,
        route: ['/pep-system/role-management'],
        icon: 'note_add',
        authorize: Authorize.ManageRoles
      }

    ]
  },
  {
    title: 'SYSTEM INTEGRATIONS',
    icon: 'account_balance',
    visible: true,
    route: ['/'],
    authorize: Authorize.StudentAccounting,
    children: [
      {
        title: 'Post SA Transactions',
        visible: true,
        route: ['/pep-system/account-transactions'],
        icon: 'note_add'
      },
      {
        title: 'Build New Loan File',
        visible: true,
        route: ['/pep-system/uas-outbound'],
        icon: 'note_add',
        authorize: Authorize.UasOutbound
      },
      {
        title:'Build Base Year Account Journal',
        visible:true,
        route:['/pep-system/base-year-account-journal'],
        icon:'node_add',
        authorize: Authorize.BaseYearAccountJournal
      },
    ]
  },
  {
    title: 'COMMUNICATIONS',
    icon: 'email',
    visible: true,
    route: ['/'],
    authorize: Authorize.Correspondence,
    children: [
      {
        title: 'Generate Bulk Correspondence',
        visible: true,
        route: ['/pep-system/correspondence'],
        icon: 'note_add'
      }
    ]
  }
];
