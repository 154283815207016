import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { BreakpointService } from '../../../shared/services/breakpoint.service';
import { Observable } from 'rxjs';
import { ProgressService } from '../../services/progress.service';
import { Disposable } from '../../../shared/extensions/disposable';
import { ENV_CONFIG, EnvConfig } from '../../../../configuration';
import { ActivatedRoute } from '@angular/router';
import { AuthService, User } from '../../../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Disposable implements OnInit {
  @Output() expandSideMenuClick = new EventEmitter<void>();
  @Input() sideNavVisible = true;
  public isHandSet$: Observable<boolean>;
  public isTablet$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public currentUser$: Observable<User>;
  public isHandSetOrTablet$: Observable<boolean>;

  constructor(
    private breakpointService: BreakpointService,
    private progress: ProgressService,
    @Inject(ENV_CONFIG) private readonly envConfig: EnvConfig,
    private readonly authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isHandSet$ = this.breakpointService.isHandset$;
    this.isTablet$ = this.breakpointService.isTablet$;
    this.isHandSetOrTablet$ = this.breakpointService.isHandsetOrTablet$;
    this.isLoading$ = this.progress.isLoading$;
    this.currentUser$ = this.authService.user;
  }

  expandClick(): void {
    this.expandSideMenuClick.emit();
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  onProfileClick(): void {}
}
