import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthorizedAnyDirective, AuthorizedDirective} from './directives/authorized.directive';
import {NotAuthorizedComponent} from './components/should-login/should-login.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthCallbackComponent } from './components/auth-call-bck/auth-callback.component';

@NgModule({
  declarations: [AuthorizedDirective, NotAuthorizedComponent, AuthCallbackComponent, AuthorizedAnyDirective],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    AuthRoutingModule
  ],
  exports: [
    AuthorizedDirective,
    AuthorizedAnyDirective,
    NotAuthorizedComponent,
    AuthCallbackComponent
  ]
})
export class AuthModule {
}
