import {AcademicLevel, ApplicationStatus, ComponentType, MenuType, Prefix, StateAbbreviation} from './apis/api.client';

export class AppConstants {
  public static readonly LastUrlKeyName = 'pepLastUrl';
}

export const academicLevelOptions = [
  {
    label: 'First Year',
    value: AcademicLevel.FirstYear,
    fallMaxSemesters: 8,
    springMaxSemesters: 7
  },
  {
    label: 'Sophomore',
    value: AcademicLevel.Sophomore,
    fallMaxSemesters: 6,
    springMaxSemesters: 5
  },
  {
    label: 'Junior',
    value: AcademicLevel.Junior,
    fallMaxSemesters: 4,
    springMaxSemesters: 3
  },
  {
    label: 'Senior',
    value: AcademicLevel.Senior,
    fallMaxSemesters: 2,
    springMaxSemesters: 1
  }
];



export const prefixOptions = [
  { label: '', value: null },
  { label: 'Mr.', value: Prefix.Mr },
  { label: 'Mrs.', value: Prefix.Mrs },
  { label: 'Ms.', value: Prefix.Ms },
  { label: 'Dr.', value: Prefix.Dr },
  { label: 'Rabbi', value: Prefix.Rabbi }
];

export const stateOptions = [
  { label: 'AL', value: StateAbbreviation.AL },
  { label: 'AK', value: StateAbbreviation.AK },
  { label: 'AS', value: StateAbbreviation.AS },
  { label: 'AZ', value: StateAbbreviation.AZ },
  { label: 'AR', value: StateAbbreviation.AR },
  { label: 'CA', value: StateAbbreviation.CA },
  { label: 'CO', value: StateAbbreviation.CO },
  { label: 'CT', value: StateAbbreviation.CT },
  { label: 'DE', value: StateAbbreviation.DE },
  { label: 'DC', value: StateAbbreviation.DC },
  { label: 'FL', value: StateAbbreviation.FL },
  { label: 'GA', value: StateAbbreviation.GA },
  { label: 'GU', value: StateAbbreviation.GU },
  { label: 'HI', value: StateAbbreviation.HI },
  { label: 'ID', value: StateAbbreviation.ID },
  { label: 'IL', value: StateAbbreviation.IL },
  { label: 'IN', value: StateAbbreviation.IN },
  { label: 'IA', value: StateAbbreviation.IA },
  { label: 'KS', value: StateAbbreviation.KS },
  { label: 'KY', value: StateAbbreviation.KY },
  { label: 'LA', value: StateAbbreviation.LA },
  { label: 'ME', value: StateAbbreviation.ME },
  { label: 'MD', value: StateAbbreviation.MD },
  { label: 'MA', value: StateAbbreviation.MA },
  { label: 'MI', value: StateAbbreviation.MI },
  { label: 'MN', value: StateAbbreviation.MN },
  { label: 'MS', value: StateAbbreviation.MS },
  { label: 'MO', value: StateAbbreviation.MO },
  { label: 'MT', value: StateAbbreviation.MT },
  { label: 'NE', value: StateAbbreviation.NE },
  { label: 'NV', value: StateAbbreviation.NV },
  { label: 'NH', value: StateAbbreviation.NH },
  { label: 'NJ', value: StateAbbreviation.NJ },
  { label: 'NM', value: StateAbbreviation.NM },
  { label: 'NY', value: StateAbbreviation.NY },
  { label: 'NC', value: StateAbbreviation.NC },
  { label: 'ND', value: StateAbbreviation.ND },
  { label: 'OH', value: StateAbbreviation.OH },
  { label: 'OK', value: StateAbbreviation.OK },
  { label: 'OR', value: StateAbbreviation.OR },
  { label: 'PA', value: StateAbbreviation.PA },
  { label: 'PR', value: StateAbbreviation.PR },
  { label: 'RI', value: StateAbbreviation.RI },
  { label: 'SC', value: StateAbbreviation.SC },
  { label: 'SD', value: StateAbbreviation.SD },
  { label: 'TN', value: StateAbbreviation.TN },
  { label: 'TX', value: StateAbbreviation.TX },
  { label: 'UT', value: StateAbbreviation.UT },
  { label: 'VT', value: StateAbbreviation.VT },
  { label: 'VA', value: StateAbbreviation.VA },
  { label: 'VI', value: StateAbbreviation.VI },
  { label: 'WA', value: StateAbbreviation.WA },
  { label: 'WV', value: StateAbbreviation.WV },
  { label: 'WI', value: StateAbbreviation.WI },
  { label: 'WY', value: StateAbbreviation.WY },
  { label: 'OTHER', value: StateAbbreviation.OTHER }
];

